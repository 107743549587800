import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import { RoundSymbol } from "../components/roundSymbol"
import "./about.scss"
import * as R from "ramda"
import { graphql, useStaticQuery } from "gatsby"
import { IMeta } from "../types/models"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

export default function About({ data }: any) {
  const intl = useIntl()
  const meta = data
  const about = R.pathOr("", ["strapiAbout", intl.locale], meta)

  const aboutText = R.pipe(
    R.split("\n"),
    R.map((section: string) => {
      if (section.indexOf("[contact@hem.fm](mailto:contact@hem.fm)") > -1) {
        const r = section.split("[contact@hem.fm](mailto:contact@hem.fm)")
        const result = R.insert(
          1,
          <span>
            <a href={"mailto:contact@hem.fm"}>contact@hem.fm</a>
          </span>
        )(r)
        return <p>{result}</p>
      } else {
        return <p>{section}</p>
      }
    })
  )(about)

  return (
    <>
      <Seo title={"About"} />
      <main className="about">
        <h1 className="about-header PPG-Bold hao">
          HEM stands for Hao Electronic Music.
        </h1>
        <div className="about-content grid grid-cols-1 md:grid-cols-2">
          <div className="order-2 md:order-1 flex items-stretch md:border-r md:border-t border-black">
            <div
              style={{ width: 64 }}
              className="hidden md:flex justify-center p-2.5 md:border-r md:border-black"
            >
              <RoundSymbol size={40} borderSize={3} color="black" />
            </div>
            <div className="flex-1 about-intro p-4 ABC">{aboutText}</div>
          </div>
          <div className="order-1 md:order-2 about-teacher">
            <StaticImage
              src="../images/about.png"
              alt="About"
              className="teacher"
            />
          </div>
        </div>
      </main>
    </>
  )
}

export const query = graphql`
  {
    strapiAbout {
      en
      ja
      zh
    }
    strapiMeta {
      contact {
        Bandcamp
        Spotify
        Twitter
        Weibo
        YouTube
        Instagram
      }
    }
  }
`
